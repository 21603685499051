import React from 'react';
import { graphql, Link } from 'gatsby';
import TermsAndConditions from '../components/terms';
import { SEO } from '../components/SEO';
import Layout from '../components/layout';
import Customization from '../components/customization';
import CourseContent from '../components/course-content';
import { SignupForCourseSidebar } from '../components/signupForCourseSidebar';
import { ContactFormSidebar } from '../components/contactFormSidebar';
import { getKeyFromString } from '../utils/utils.js';
import { okBaseUrl } from '../utils/config.js';
import Logo from '../images/Logo_white@2x.png';
import style from './course.module.scss';
import learningsImage from '../images/num_of_participants@1x.png';
import loaderImage from '../images/ajax-loader.gif';

export default ({ location, data }) => {
  //const { location, data } = props;
  const course = data.coursesJson;
  const classTraining = data.allClassTrainingsJson.nodes[0];
  return (
    <>
      <SEO title={course.meta.title} description={course.meta.description} />
      <Layout
        location={location}
        mainTitle={course.title.fi}
        mainTitleClasses={'mt-3'}
        additionalTitle={
          course.meta.show_additional_title
            ? course.short_tagline
              ? course.short_tagline
              : 'Yrityksellesi räätälöitynä'
            : undefined
        }
        additionalTitleClassNames={'mb-4 mt-md-0 mb-md-4'}
        tagLine={course.tagline.fi}
        headerNavLinkStyle={{ color: 'white' }}
        logo={Logo}
        location={location}
      >
        <div className={style.coursePageContent + ' '}>
          <div className={style.trainingContent + ' ' + 'px-5'}>
            <div className="row d-flex justify-content-center pt-0 pb-4">
              <div className="col-lg-6 col-md-6 pt-5 pr-md-6 mb-2">
                <h2 className="course-signup-form-title text-center mb-4">
                  Koulutuksen jälkeen
                </h2>
                <ul
                  className={
                    style.coursePageContent + ' checkList ' + style.checkList
                  }
                >
                  {course.learnings.fi.map(item => (
                    <li key={getKeyFromString(item)} className="mb-4">
                      {item}
                    </li>
                  ))}
                </ul>
                <div className="row">
                  <div className="col-sm-8 offset-sm-4 col-md-10 offset-md-2 col-lg-8 offset-lg-4">
                    <div className="image-wrapper my-6">
                      <img className="w-100" src={learningsImage} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-6">
                <div
                  className={
                    style.contactUsContainer +
                    ' ' +
                    'shadow shadow-except-mobile pt-3 pt-md-5 px-sm-4 pb-3'
                  }
                >
                  {data.allClassTrainingsJson.nodes.length > 0 &&
                  classTraining.active ? (
                    <SignupForCourseSidebar
                      title="Ilmoittaudu kurssille"
                      url={`${okBaseUrl}/${data.allClassTrainingsJson.nodes[0].signup_iframe}`}
                      date={classTraining.date}
                      location={classTraining.location}
                      duration={classTraining.duration}
                      price={classTraining.price}
                      mode={true}
                    />
                  ) : (
                    <ContactFormSidebar
                      title="Ota yhteyttä tai jätä tarjouspyyntö"
                      url={`${okBaseUrl}/fi/ota-yhteytta-iframe`}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="testimonial testimonial-x pb-5 px-5">
            <div className="row">
              <div className="col-sm-12 text-center">
                <div className="quote-icon"></div>
                <h2 className="quote">{course.feedback.text.fi}</h2>
                <div className="person">{course.feedback.participant.fi}</div>
              </div>
            </div>
          </div> */}
          <CourseContent
            headline={course.example_content.headline.fi}
            headline_2={course.example_content.headline_2.fi}
            example_content={course.example_content}
          />
          <div className="trainers-related px-5 pt-6 pb-6">
            <div className="row">
              <div className="col-md-6 d-flex justify-content-center">
                <div className="content">
                  <h3 className="text-uppercase mb-4">
                    {course.trainers.title.fi}
                  </h3>
                  <ul className="checkList">
                    {course.trainers.items.fi.map(item => (
                      <li key={getKeyFromString(item)} className="mb-2">
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-md-6 d-flex justify-content-center">
                <div className="content">
                  <h3 className="text-uppercase mb-4">
                    {course.related_courses.title.fi}
                  </h3>
                  <ul className="checkList">
                    {data.relatedCourses.nodes.map(item => (
                      <li
                        key={getKeyFromString(item.title.fi)}
                        className="mb-2"
                      >
                        <Link to={'/' + item.fields.fullUrlPath}>
                          {item.title.fi}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export const query = graphql`
  query($slug: String!, $coursetags: [String], $course_id: String!) {
    coursesJson(fields: { slug: { eq: $slug } }) {
      meta {
        show_additional_title
        title
        description
      }
      title {
        fi
      }
      short_tagline
      tagline {
        fi
      }
      learnings {
        fi
      }
      url_path
      feedback {
        text {
          fi
        }
        participant {
          fi
        }
      }
      trainers {
        items {
          fi
        }
        title {
          fi
        }
      }
      customization {
        headline {
          fi
        }
        description {
          fi
        }
        bullets {
          fi
        }
      }
      related_courses {
        items {
          fi
        }
        title {
          fi
        }
      }
      example_content {
        headline {
          fi
        }
        headline_2 {
          fi
        }
        column_1 {
          bullets {
            fi
          }
          headline {
            fi
            icon
          }
        }
        column_2 {
          bullets {
            fi
          }
          headline {
            fi
            icon
          }
        }
      }
    }
    relatedCourses: allCoursesJson(
      filter: { tags: { in: $coursetags }, fields: { slug: { ne: $slug } } }
    ) {
      nodes {
        fields {
          fullUrlPath
        }
        title {
          fi
        }
        tags
        url_path
      }
    }
    site {
      siteMetadata {
        paths {
          course_listing
        }
      }
    }
    allClassTrainingsJson(filter: { course_id: { eq: $course_id } }) {
      nodes {
        price
        signup_iframe
        date
        course_id
        location
        duration
        active
      }
    }
  }
`;
